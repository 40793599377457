<template>
  <div>
    <h2 v-html="$t('subsidy_invoice_title')"></h2>
    <!-- Übermittlung von -->
    <DbmMonthPicker
      id="SubsidyInvoiceRun_completionFrom"
      v-model="searchCardStore.SubsidyInvoiceRun_completionFrom"
      :dateLabel="$t('subsidy_invoice_transmission_from')"
      :clearable="true"
    />
    <!-- Übermittlung bis -->
    <DbmMonthPicker
      id="SubsidyInvoiceRun_completionUntil"
      v-model="searchCardStore.SubsidyInvoiceRun_completionUntil"
      :dateLabel="$t('subsidy_invoice_transmission_to')"
      :clearable="true"
      returnType="firstOfNextMonth"
    />
    <!-- Übermittlung am -->
    <DbmDatePicker v-model="searchCardStore.SubsidyInvoiceRun_completion" :dateLabel="$t('subsidy_invoice_transmission_run')" :clearable="true" />
    <!-- Status Übermittlung -->
    <v-select
      id="subsidy_invoice_transmission_status"
      v-model="searchCardStore.SubsidyInvoice_status"
      :label="$t('subsidy_invoice_transmission_status')"
      :items="subsidyInvoiceStatuses"
      :item-title="this.$getLangKey()"
      item-value="name"
      clearable
      @keyup.enter="search"
    />

    <div class="link align-right" @click="expanded = !expanded">
      <span v-if="!expanded" v-html="$t('more')"></span>
      <span v-else v-html="$t('less')"></span>
    </div>
    <div v-if="expanded">
      <!-- expanded here -->
      <!-- Ablage-Nr -->
      <v-text-field
        id="subsidy_invoice_invoice_nr"
        v-model="searchCardStore.SubsidyInvoice_id"
        :label="$t('subsidy_invoice_invoice_nr')"
        type="text"
        @keyup.enter="search"
      />
      <!-- Betrag min -->
      <v-text-field
        id="subsidy_invoice_amount_min"
        v-model="searchCardStore.SubsidyInvoice_amountMin"
        :label="$t('subsidy_invoice_amount_min')"
        type="text"
        @keyup.enter="search"
      />
      <!-- Betrag max -->
      <v-text-field
        id="subsidy_invoice_amount_max"
        v-model="searchCardStore.SubsidyInvoice_amountMax"
        :label="$t('subsidy_invoice_amount_max')"
        type="text"
        @keyup.enter="search"
      />
    </div>
  </div>
</template>

<script lang="ts">
import baseSearchVue from './baseSearch.vue'
import DbmMonthPicker from '@/components/dbmMonthPicker.vue'
import DbmDatePicker from '@/components/dbmDatePicker.vue'
import { useSearchSubsidyInvoiceStore } from '@/store/SearchSubsidyInvoiceStore'
import { useSubsidyInvoiceStatusesStore } from '@/store/enums/SubsidyInvoiceStatusesStore'

import { defineComponent } from 'vue'
export default defineComponent({
  name: 'searchSubsidyInvoice',
  extends: baseSearchVue,
  data() {
    return {
      expanded: false,
      menuTime: false
    }
  },
  computed: {
    subsidyInvoiceStatuses() {
      useSubsidyInvoiceStatusesStore().subsidyInvoiceStatuses
    }
  },
  components: {
    DbmMonthPicker: DbmMonthPicker,
    DbmDatePicker: DbmDatePicker
  },
  methods: {},
  beforeMount() {
    useSubsidyInvoiceStatusesStore().fetchSubsidyInvoiceStatuses()
    this.setSearchCardStore(useSearchSubsidyInvoiceStore(), true)
  }
})
</script>
