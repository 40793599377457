import { defineStore } from 'pinia'
import { TermItemDefinition } from '@/services/term'

export const useSearchSubsidyInvoiceStore = defineStore('searchSubsidyInvoiceStore', {
  state: () => ({
    SubsidyInvoiceRun_completionFrom: null as string | null,
    SubsidyInvoiceRun_completionUntil: null as string | null,
    SubsidyInvoiceRun_completion: null as string | null,
    SubsidyInvoice_status: null as string | null,
    SubsidyInvoice_id: null as number | null,
    SubsidyInvoice_amountMin: null as number | null,
    SubsidyInvoice_amountMax: null as number | null,
    items: [
      { key: 'SubsidyInvoiceRun_completionFrom', operation: '>=' },
      { key: 'SubsidyInvoiceRun_completionUntil', operation: '<' },
      { key: 'SubsidyInvoiceRun_completion', operation: ':' },
      { key: 'SubsidyInvoice_status', operation: ':' },
      { key: 'SubsidyInvoice_id', operation: ':' },
      { key: 'SubsidyInvoice_amountMin', operation: '>=' },
      { key: 'SubsidyInvoice_amountMax', operation: '<=' }
    ] as TermItemDefinition[]
  })
})
